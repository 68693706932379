import React from 'react';;
import '@styles/search.scss';


const ToastModal = ({message}) =>{

    return (
        message &&
        <div className="position-fixed top-0 end-0 p-3" style={{ zIndex: 1021 }}>
            <div id="liveToast" className="toast bg-danger" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-body">
                    {message}
                </div>
            </div>
        </div>
    )
}

export default ToastModal;