import React from 'react';
import $ from 'jquery';

import '@styles/ModalDetailStore.scss';

const ModalResponseToLogin = ({message, url}) => {
    const handleClose = () => {
        $('#response-modal-login').modal('hide');
    };

    return (
        <div className="Response-modal">
            <div className="modal fade" id="response-modal-login" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" onClick={handleClose}  aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="Response-content">
                                <div className='header-text'>
                                    <p className='title-text'>Para poder seleccionar esta opción debes haber iniciado sesión previamente</p>
                                </div>
                                <div className='context-text'>
                                    {message}
                                </div>
                                <div className='button' onClick={handleClose}>
                                    Entendido
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalResponseToLogin;